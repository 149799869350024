import React, { useState } from "react";
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import {
  ButtonOutlined,
  ButtonPrimary,
  CurrentInvestingSelector,
  InvestmentConcernSelector,
  InvestmentDropResponseSelector,
  InvestmentExperienceSelector,
  InvestmentRiskSelector,
  InvestTermSelector,
} from "@components";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import { IlustrationDiscover } from "@images";
import { useAuth, useForm } from "@hooks";
import { calculateSuggestedProfile } from "@utils";

const Descubrir = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const [form] = useForm();
  const [investTerm, setInvestTerm] = useState<string>(
    form.investmentTerm ? form.investmentTerm : ""
  );
  const [risk, setRisk] = useState<string>(form.risk ? form.risk : "");
  const [experience, setExperience] = useState<string>(
    form.investmentExperience ? form.investmentExperience : ""
  );
  const [concern, setConcern] = useState<string>(
    form.investmentConcern ? form.investmentConcern : ""
  );
  const [current, setCurrent] = useState<string>(
    form.currentInvesting ? form.currentInvesting : ""
  );
  const [drop25, setDrop25] = useState<string>(
    form.investmentDropResponse ? form.investmentDropResponse : ""
  );
  const [investorProfile, setInvestorProfile] = useState("");

  const handleDiscoverClick = () => {
    const result = calculateSuggestedProfile(investTerm, {
      risk: risk,
      investmentExperience: experience,
      investmentConcern: concern,
      currentInvesting: current,
      investmentDropResponse: drop25,
    });
    form.risk = risk;
    form.investmentExperience = experience;
    form.investmentConcern = concern;
    form.currentInvesting = current;
    form.investmentDropResponse = drop25;
    form.investmentTerm = investTerm;
    setInvestorProfile(result?.profile);
  };

  const isMobile = useMediaQuery("(max-width: 1280px)");
  const [isStep, setStep] = useState(-1);
  return (
    <LayoutBudget
      title="Test de Perfil de Inversión"
      subtitle={
        isStep === -1
          ? "Paso 1: cuéntanos un poco de ti"
          : isStep === 0
          ? "Paso 2: sólo un par de preguntas más"
          : "Paso 3: Tu resultado"
      }
      onTurnBackClick={() =>
        isStep === -1
          ? navigate(Route.rokipedia+ "/?#tools")
          : isStep === 0
          ? setStep(-1)
          : setStep(0)
      }
    >
      {isStep !== 1 && (
        <div className={classes.title}>
          Así podrás descubrir inversiones que se ajusten a tus objetivos.
        </div>
      )}
      {isStep === -1 && (
        <section className={classes.containerData}>
          <InvestTermSelector
            name="investmentTerm"
            onChange={(e) => setInvestTerm(e.target.value as string)}
            value={investTerm}
            label="Retiraré dinero en un plazo de"
          />
          <InvestmentRiskSelector
            name="investmentRisk"
            onChange={(e) => setRisk(e.target.value as string)}
            value={risk}
            label="Si invierto 1 millón"
          />
          <InvestmentExperienceSelector
            className={classes.input}
            name="investmentExperience"
            onChange={(e) => setExperience(e.target.value as string)}
            value={experience}
            label="Mi experiencia invirtiendo es:"
          />
          <div className={classes.buttonContinue}>
            <ButtonPrimary
              text="Continuar"
              onClick={() => {
                setStep(0);
              }}
            />
          </div>
        </section>
      )}
      {isStep === 0 && (
        <section className={classes.containerData}>
          <InvestmentConcernSelector
            name="investmentConcern"
            onChange={(e) => setConcern(e.target.value as string)}
            value={concern}
            label="Cuándo invierto:"
          />
          <CurrentInvestingSelector
            name="currentInvesting"
            onChange={(e) => setCurrent(e.target.value as string)}
            value={current}
            label="Actualmente invierto en:"
          />
          <InvestmentDropResponseSelector
            name="investmentDropResponse"
            onChange={(e) => setDrop25(e.target.value as string)}
            value={drop25}
            label="Si mi inversión cae un 25%, tu:"
          />
          <div className={classes.buttonContinue}>
            <ButtonPrimary
              text="Continuar"
              onClick={() => {
                handleDiscoverClick();
                setStep(1);
              }}
            />
          </div>
        </section>
      )}
      {isStep === 1 && (
        <section className={classes.finish}>
          {!isMobile && (
            <img src={IlustrationDiscover} className={classes.ilustration} />
          )}
          <div className="right">
            <div className={"card"}>
              {isMobile && (
                <img
                  src={IlustrationDiscover}
                  className={classes.ilustration}
                />
              )}
              <div className="title">Tu perfil recomendado</div>
              <div className="profile">{investorProfile}</div>
              <div className="text">{description[investorProfile]}</div>
            </div>
            <div className="buttons">
              <ButtonOutlined
                text="Ir atras y editar"
                onClick={() => {
                  setStep(-1);
                }}
              />
              <ButtonPrimary
                text="Guardar y volver a Mis finanzas"
                onClick={() => {
                  navigate(Route.finance);
                }}
              />
            </div>
          </div>
        </section>
      )}
    </LayoutBudget>
  );
};
export default Descubrir;

const useStyles = makeStyles<Theme>((theme) => ({
  containerData: {
    display: "grid",
    maxWidth: 1100,
    margin: "0 auto",
    boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
    background: "white",
    padding: "20px 40px",
    borderRadius: 30,
    marginBottom: "10rem",
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1280)]: {
      width: 375,
    },
    "& >div": {
      width: 520,
      [theme.breakpoints.down(1280)]: {
        width: 300,
      },
      margin: "15px 0",
    },
  },
  buttonContinue: {
    width: "100%!important",
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down(1280)]: {
      justifyContent: "center",
    },
  },
  title: {
    maxWidth: 1100,
    margin: "0 auto",
    fontFamily: "Montserrat",
    paddingLeft: 50,
    marginTop: 50,
    marginBottom: 30,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1280)]: {
      width: 375,
      paddingLeft: 0,
      textAlign: "center",
    },
  },
  finish: {
    display: "flex",
    justifyContent: "center",
    marginLeft: "5rem",
    marginTop: "2rem",
    gap: "7rem",
    marginBottom: "2rem",
    fontFamily: "Montserrat",
    [theme.breakpoints.down(1280)]: {
      marginLeft: 0,
    },
    "& .buttons": {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down(1280)]: {
        display: "grid",
        gap: "2rem",
        justifyContent: "center",
        margin: 0,
      },
    },
    "& .card": {
      background: "flex",
      boxShadow: "4px 4px 20px 2px rgba(0, 0, 0, 0.2)",
      borderRadius: 20,
      width: 520,
      height: 400,
      display: "grid",
      gridTemplateRows: "100px 50px 200px",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: "4rem",
      [theme.breakpoints.down(1280)]: {
        width: 375,
        minHeight: 400,
        height: "auto",
        gridTemplateRows: "190px 48px 49px 104px",
        "& img": {
          width: 200,
          margin: "0 auto",
        },
      },
      "& .title": {
        fontSize: 20,
        fontWeight: 300,
        textTransform: "uppercase",
      },
      "& .profile": {
        fontFamily: "Montserrat",
        fontSize: 40,
        fontWeight: "bold",
      },
      "& .text": {
        fontWeight: 300,
      },
    },
  },
}));
const description: { [key: string]: string } = {
  Conservador:
    "Eres un inversionista que se caracteriza por ser el menos tolerante al riesgo y valorar la seguridad, aunque esto implique que las ganancias obtenidas sean bajas.",
  "Conservador Moderado":
    "Eres poco tolerante al riesgo, **buscas estabilidad antes que el crecimiento de tu inversión.**",
  Moderado:
    "Eres un inversionista cauteloso con tus decisiones, pero estás dispuesto a correr cierto riesgo para aumentar tus ganancias.",
  "Moderado Agresivo":
    "Eres un inversionista tolerante al riesgo, buscas el crecimiento de tu inversión, pero de igual forma buscas cierta seguridad.",
  Agresivo:
    "Eres un inversionista amante al riesgo! Buscas la mayor rentabilidad posible, a pesar de que eso implique obtener ganancias o pérdidas repentinas.",
};
